import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP } from "../../../config";
import { patch } from "../../../library/Requests/helpers";

export const getTimeSlotsAPI = createAsyncThunk(
  "bookingScreen/getTimeSlotsAPI",
  async (obj: any) => {
    const RequestBodyLists = [
        {
            type:"doctor",
            body:{
                    date: obj?.date,
                    doctorId: obj?.doctorId,
                    test_code: obj?.test_code,
                    vendorId: obj?.vendorId,
            }
        },
        {
            type:"vendor",
            body:{
                date: obj?.date,
                vendorId: obj?.vendorId,
                test_code: obj?.test_code,
            }
        }
    ]

    const requestBody = RequestBodyLists.find((item)=>{
        return item?.type===obj?.type
    })

    return await patch(`${SERVER_IP}/api/v1/timeslot/${requestBody?.type}`, requestBody?.body);
  }
);
