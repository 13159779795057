import React,{useEffect, useState} from 'react'
import { Form } from 'react-bootstrap'
import { IndexStyle } from "./Index.style";
import { useDispatch, useSelector } from 'react-redux';
import { getAllVendorBookings } from '../../../redux/slices/vendor/vendorService';
import { RootState } from '../../../redux/store';


const SearchUser = ({setFilter}:any) => {

    const dispatch = useDispatch()

    const {user } = useSelector((store:RootState)=>store.auth)

    const [searchText,setSearchText] = useState<string>("")

    useEffect(()=>{
        console.log("hduww")
        const timer = setTimeout(()=>{
            dispatch(getAllVendorBookings(user?.id))
            setFilter({
                search:searchText
            })
        },250)

        return ()=>{
            clearTimeout(timer)
        }
    },[searchText,dispatch,user?.id])

    const handleChangeValue = (e:any)=>{
        setSearchText(e.target.value)
    }

    return (
        <IndexStyle>
            <div className="create-new-institute-sec-content-all">
                <div className="student-fields-sec-content-all deltape-form">
                    <div className="student-info-row">
                    <Form.Group className="delta-signup-md" controlId="email">
                        <Form.Label> Search</Form.Label>
                        <Form.Control
                        placeholder="Search: Name, Email, Phone"
                        name="studentName"
                        type="text"
                        value={searchText}
                        onChange={handleChangeValue}
                        />
                    </Form.Group>
                    </div>
                </div>
            </div>
        </IndexStyle>
    )
}

export default SearchUser