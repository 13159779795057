export const API_BASE = "/api";
export const GRAPH_BASE = "https://graph.facebook.com/v6.0";
const hostName = window.location.hostname;

let SERVER_IP_URL;
let CHAT_API_URL;

SERVER_IP_URL = "https://api.raphacure.co.in";
CHAT_API_URL = "https://chat-api.raphacure.com";
// CHAT_API_URL = "http://localhost:3001";
// SERVER_IP_URL = "http://localhost:3010";
// SERVER_IP_URL = "https://api.raphacure.com";
if (typeof window !== "undefined") {
  if (
    hostName.indexOf("vendor.raphacure.com") !== -1 &&
    hostName.indexOf("staging.vendor.raphacure.com") === -1
    //  || hostName === "localhost"
  ) {
    SERVER_IP_URL = "https://api.raphacure.com";
  }
}
console.log("SERVER_IP_URL : ", SERVER_IP_URL);

export const SERVER_IP = SERVER_IP_URL;
export const CHAT_API = CHAT_API_URL;
