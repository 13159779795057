import React, { useEffect} from "react";
import { IndexsStyled } from "./Index.styled";
import { useDispatch, useSelector } from "react-redux";
import { Tabs } from "antd";

import BookingTab from "../../components/booking/bookingTabs/BookingTab";
import { getAllVendorBookings } from "../../redux/slices/vendor/vendorService";
import { RootState } from "../../redux/store";

const ManageBookings = () => {

  const dispatch = useDispatch();


  
  const { bookingList } = useSelector((state: any) => state?.vendor);

  const tabTypes = {
    all:bookingList.filter((booking:any)=>{
          return ["open","accepted","rejected","in_progress","completed","reopen"].includes(booking.vendor_status)
        }),
    pending:bookingList.filter((booking:any)=>{
          return booking.vendor_status==='open'
        }),
    accepted:bookingList.filter((booking:any)=>{
          return booking.vendor_status==='accepted'
        }),
    complected:bookingList.filter((booking:any)=>{
          return booking.vendor_status==='completed'
        }),
    rejected:bookingList.filter((booking:any)=>{
          return booking.vendor_status==='rejected'
        })
  }

  const {user } = useSelector((store:RootState)=>store.auth)

  useEffect(()=>{
    dispatch(getAllVendorBookings(user?.id))
  },[dispatch,user?.id])


  return (
    <>
      <IndexsStyled>
        <div className="freshbag-wrapper">
          <div className="rapha-tabs-sec">
            <Tabs
              defaultActiveKey="0"
              onChange={async()=>{
                dispatch(getAllVendorBookings(user?.id))
              }}
              items={[
                {
                  label: `All Bookings(${tabTypes.all.length})`,
                  key: "0",
                  children: <BookingTab tabType="all" bookings={tabTypes.all} />,
                },
                {
                  label: `Pending Bookings(${tabTypes.pending.length})`,
                  key: "1",
                  children: <BookingTab tabType="open" bookings={tabTypes.pending} />,
                },
                {
                  label: `Open Bookings(${tabTypes.accepted.length})`,
                  key: "2",
                  children: <BookingTab tabType="accepted" bookings={tabTypes.accepted} />,
                },
                {
                  label: `Completed Bookings(${tabTypes.complected.length})`,
                  key: "3",
                  children: <BookingTab tabType="completed" bookings={tabTypes.complected}  />,
                },
                {
                  label: `Rejected Bookings(${tabTypes.rejected.length})`,
                  key: "4",
                  children: <BookingTab tabType="rejected" bookings={tabTypes.rejected} />,
                },
              ]}
            />
          </div>
        </div>
      </IndexsStyled>
    </>
  );
};

export default ManageBookings