import React, { useEffect, useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { useDispatch, useSelector } from "react-redux";
import DomainNotFound from "./../DomainNotFound";

import Sidebar from "../SideMenu/Sidebar";

const Layout = (props) => {
  useEffect(() => {}, []);
  const { error, loading, user } = useSelector(({ auth }) => auth);
  const urlP = window.location.pathname;
  const patha = window.location.hostname;
  const [isInvalidDomain, setIsInvalidDomain] = useState(false);

  useEffect(() => {
    // getSubdomainInfo();
  }, []);
  const allowDomains = ["aaaaaa"];
  const getSubdomainInfo = async () => {
    try {
      const patha1 = window.location.hostname;

      let pathName = patha1?.split(".")[0] ?? "";
      console.log("pathName", pathName);
      if (
        pathName === "localhost" ||
        pathName === "raphacure" ||
        pathName === "www"
      ) {
        pathName = "";
      }
      if (pathName && pathName !== "" && !allowDomains.includes(pathName)) {
        // setIsInvalidDomain(true);
      }
    } catch (e) {
      console.log("error", e);
    }
  };
  console.log("urlP", urlP);
  return (
    <div className={"wrapper-hide-header"}>
      {isInvalidDomain ? (
        <DomainNotFound />
      ) : (
        <>
          <Header />
          <div className="layout-container full-content-sec-page-design1">
            {/* {user?._id && (
          <div className="my-account-sec-top">
            <h2>Extension Manager</h2>
            <p>Manage your Extension seamless.</p>
          </div>
        )} */}
            {urlP !== "/signin" &&
              urlP !== "/signup_vinod_0599" &&
              urlP !== "/signup_vinod_0599" &&
              urlP !== "/termscondition" &&
              urlP !== "/policy" && (
                <h2 className="sunset-deals-header">{user?.fullName}</h2>
              )}

            <div className="my-account-sec-bottom">
              {urlP !== "/signin" &&
              urlP !== "/signup_vinod_0599" &&
              urlP !== "/chatroom" &&
              urlP !== "/signup_vinod_0599" &&
              urlP !== "/termscondition" &&
              urlP !== "/policy" ? (
                <>
                  <div className="left-menu-side-content">
                    <Sidebar />
                  </div>
                  <div className="right-menu-side-content">
                    {" "}
                    {props.children}
                  </div>
                </>
              ) : (
                <div className="right-menu-side-content1">
                  {" "}
                  {props.children}
                </div>
              )}
            </div>
          </div>
          {/* <Footer /> */}
        </>
      )}
    </div>
  );
};

export default Layout;
