import React, { useState } from "react";
import {  Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Form, Input } from "antd";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { Button, Upload } from "antd";
import type { UploadFile } from "antd/es/upload/interface";
import {
  getAllVendorBookings,
  updateVendorStatusAPI,
} from "../../../redux/slices/vendor/vendorService";
import { RootState } from "../../../redux/store";
import { getS3PresignedUrl } from "../../../redux/slices/auth/authService";
import {
  CustomPreviewModalStyle,
  CustomUploadStyle,
  CustomViewModalStyle,
  ModalStyle,
} from "./Modal.styles"
import { Modal as ModalBootStrap } from "react-bootstrap";
import RescheduleBooking from "../rescheduleBooking/RescheduleBooking";
import { toast } from "react-toastify";
import LabTestDetails from "../../labTest/LabTestDetails";

const actions = [
    {
      type: "accept",
      title: "Accept Booking",
      question: "Are you sure you want to Accept the Booking",
      Component({
        open,
        confirmLoading,
        handleCancel,
        setConfirmLoading,
        selectedbooking,
      }: any) {
        const dispatch = useDispatch();
  
        const {user} = useSelector((store:RootState)=>store.auth)
  
        const handleOk = async () => {
          setConfirmLoading(true);
          const body = {
            booking_id: selectedbooking?.id,
            vendor_id: selectedbooking?.vendor_id,
            vendor_status: "accepted",
          };
          try {
            const result = (await dispatch(updateVendorStatusAPI(body))) as any;
            if (result?.meta?.requestStatus === "fulfilled") {
              toast.success("The booking has been accepted");
              handleCancel();
              dispatch(getAllVendorBookings(user?.id));
            } else if (result?.meta?.requestStatus === "rejected") { 
              toast.error(result?.error?.message);
            }
          } catch (error) {
            console.log(error);
            toast.error(JSON.stringify(error));
          } finally {
            setConfirmLoading(false);
          }
        };
        return (
          <Modal
            okText="Accept Booking"
            title={this.title}
            open={open}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
          >
            <p>{this?.question}</p>
          </Modal>
        );
      },
    },
    {
      type: "reject",
      title: "Reject Booking",
      question: "Are you sure you want to Reject the Booking",
      Component({
        open,
        confirmLoading,
        handleCancel,
        setConfirmLoading,
        selectedbooking,
        messageApi,
      }: any) {
        const dispatch = useDispatch();
        const { TextArea } = Input;
        const [reason, setReason] = useState("");
  
        const {user} = useSelector((store:RootState)=>store.auth)
  
        const handleOk = async () => {
          if (reason?.length < 1) {
            return;
          }
          const body = {
            booking_id: selectedbooking?.id,
            vendor_id: selectedbooking?.vendor_id,
            vendor_status: "rejected",
            rejected_reason: reason,
          };
          try {
            setConfirmLoading(true);
            const result = (await dispatch(updateVendorStatusAPI(body))) as any;
            if (result?.meta?.requestStatus === "fulfilled") {
              toast.success("The booking has been rejected");
              handleCancel();
              dispatch(getAllVendorBookings(user?.id));
            } else if (result?.meta?.requestStatus === "rejected") { 
              toast.error(result?.error?.message);
            }
          } catch (error) {
            console.log(error);
            toast.error(JSON.stringify(error));
          } finally {
            setConfirmLoading(false);
          }
        };
  
        return (
          <>
            <Modal
              okText="Reject Booking"
              okType="danger"
              title={this.title}
              open={open}
              onOk={handleOk}
              confirmLoading={confirmLoading}
              onCancel={handleCancel}
            >
              <p>{this?.question}</p>
              <Form.Item label="Reason">
                <TextArea
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  rows={4}
                />
              </Form.Item>
            </Modal>
          </>
        );
      },
    },
    {
      type: "upload_lab_result",
      question: "Upload all the Documents",
      title: "Upload the Documents",
      Component({
        open,
        confirmLoading,
        handleCancel,
        setConfirmLoading,
        selectedbooking,
      }: any) {
        const [fileList, setFileList] = useState<UploadFile[]>([]);
        const [publicUrls, setPublicUrls] = useState([]) as any;
  
        const dispatch = useDispatch();
  
        const {user} = useSelector((store: RootState) => store.auth);
  
        const fileUploadAWS = async () => {
          const presignBody = {
            id: `${user?.id}`,
            ext: ".pdf", // Use appropriate file extension
          };
          const presignedRes = await dispatch(getS3PresignedUrl(presignBody));
          const presignedUrlResp = JSON.parse(JSON.stringify(presignedRes));
          const presignedUrl = presignedUrlResp?.payload?.signedUrL?.signedUrL;
          const publicPdfUrl = presignedUrlResp?.payload?.signedUrL?.publicUrl;
  
          if (presignedUrl && publicPdfUrl) {
            setPublicUrls((pre: any) => [...pre, publicPdfUrl]);
            return presignedUrl;
          }
        };
  
        const handleChange: UploadProps["onChange"] = (info) => {
          let newFileList = [...info.fileList];
  
          newFileList = newFileList.slice(-10);
  
          newFileList = newFileList.map((file) => {
            if (file.response) {
              file.url = file.response.url;
            }
            return file;
          });
  
          setFileList(newFileList);
        };
  
        const props = {
          onChange: handleChange,
          multiple: true,
        };
  
        const handleOk = async () => {
          const attachments = publicUrls.map((url: string) => {
            return {
              ext: `.${url.split(".").at(-1)}`,
              url: url,
            };
          });
          const body = {
            booking_id: selectedbooking?.id,
            vendor_id: selectedbooking?.vendor_id,
            vendor_status: "completed",
            booking_Attachements: attachments,
          };
          setConfirmLoading(true);
          try {
            const result = (await dispatch(updateVendorStatusAPI(body))) as any;
            if (result?.meta?.requestStatus === "fulfilled") {
              toast.success("The documents have has been uploaded");
              handleCancel();
              dispatch(getAllVendorBookings(user?.id));
            } else if (result?.meta?.requestStatus === "rejected") { 
              toast.error(result?.error?.message);
            }
          } catch (error) {
            console.log(error);
          } finally {
            setConfirmLoading(false);
          }
        };
  
        const customItemRender = (
          originNode: any,
          file: any,
          fileList: any,
          actions: any
        ) => {
          return (
            <CustomUploadStyle>
              <div className="custom-upload-item">
                <span>{file.name}</span>
                <Button
                  type="primary"
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    actions.remove();
                    setPublicUrls((pre: any) => {
                      return pre.filter((url: string) => {
                        return url !== file.xhr.responseURL.split("?")[0];
                      });
                    });
                  }}
                >
                  Delete
                </Button>
              </div>
            </CustomUploadStyle>
          );
        };
  
        return (
          <>
            <Modal
              okText="Upload Documents"
              title={this.title}
              open={open}
              onOk={handleOk}
              confirmLoading={confirmLoading}
              onCancel={handleCancel}
            >
              <p>{this.question}</p>
              <ModalStyle>
                <Upload
                  className="upload_modal"
                  itemRender={customItemRender}
                  action={fileUploadAWS}
                  method="put"
                  accept=".pdf"
                  {...props}
                  fileList={fileList}
                >
                  <Button icon={<UploadOutlined />}>
                    {fileList.length > 1 ? "Upload more Files" : "Upload File"}
                  </Button>
                </Upload>
              </ModalStyle>
            </Modal>
          </>
        );
      },
    },
    {
      type: "preview_documents",
      title: "Preview Uploaded Documents",
      question: "Please select to download the required documents",
      Component({
        open,
        confirmLoading,
        handleCancel,
        setConfirmLoading,
        selectedbooking,
      }: any) {
        const pdfLinks = selectedbooking?.attachments;
  
        return (
          <Modal
            open={open}
            title={this.title}
            footer={null}
            onCancel={handleCancel}
          >
            <>
              <CustomPreviewModalStyle>
                <p>{this.question}</p>
                <div className="main-div">
                  {pdfLinks.map((urlObj: any) => {
                    return (
                      <>
                        <a href={urlObj?.url}>{urlObj?.url.split("/").at(-1)}</a>
                      </>
                    );
                  })}
                </div>
              </CustomPreviewModalStyle>
            </>
          </Modal>
        );
      },
    },
    {
      type: "view_booking",
      title: "Details of Booking",
      Component({
        open,
        confirmLoading,
        handleCancel,
        setConfirmLoading,
        selectedbooking,
      }: any) {
        const [isReschedule, setIsReschedule] = useState(false);
  
        const closeReschedule=()=>{
          setIsReschedule(false)
        }
  
        return (
          <>
            <ModalBootStrap
              show={open}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <ModalBootStrap.Header>
                <ModalBootStrap.Title id="contained-modal-title-vcenter">
                  {this.title}
                </ModalBootStrap.Title>
                <Button danger onClick={handleCancel}>
                  Close
                </Button>
              </ModalBootStrap.Header>
              <ModalBootStrap.Body>
                <CustomViewModalStyle>
                  <div className="main-div">
                    <h3 className="main-id">
                      <b>{selectedbooking?.id}</b>
                    </h3>
                    <div>
                      <div className="sub-div-main">
                        <h5>Order Status</h5>
                        <h5>
                          {
                            [
                              {
                                type: "completed",
                                render: "🟢 Completed",
                              },
                              {
                                type: "rejected",
                                render: "🔴 Rejected",
                              },
                              {
                                type: "open",
                                render: "🟡 Pending",
                              },
                            ].find(
                              (component) =>
                                component.type === selectedbooking?.vendor_status
                            )?.render
                          }
                        </h5>
                      </div>
                      <p>
                        ✅ Order placed on{" "}
                        {selectedbooking?.created_at.split("T")[0]} at{" "}
                        {selectedbooking?.created_at.split("T")[1].slice(0, 5)}
                      </p>
                      <p>
                        Current Status:
                        <b className="current-status">
                          {selectedbooking?.vendor_status}
                        </b>{" "}
                        and Current schedule:
                        <b>
                          {selectedbooking?.collection_1_date} at{" "}
                          {selectedbooking?.collection_1_slot}
                        </b>{" "}
                      </p>
                      <hr />
                    </div>
                    <div>
                      <div className="scheduled-booking-head">
                        <h5>Scheduled Date and Time</h5>
                        <p>
                          <b>
                            {selectedbooking?.collection_1_date} at{" "}
                            {selectedbooking?.collection_1_slot}
                          </b>{" "}
                        </p>
                      </div>
                      {isReschedule ? (
                        <>
                          <Space
                            className="custom-space-width"
                            direction="vertical"
                          >
                            <Button
                              danger
                              type="primary"
                              onClick={() => closeReschedule()}
                            >
                              Cancel
                            </Button>
                            <RescheduleBooking
                              setIsReschedule={closeReschedule}
                              selectedbooking={selectedbooking}
                            />
                          </Space>
                        </>
                      ) : (
                        <Button
                          type="primary"
                          onClick={() => setIsReschedule(true)}
                        >
                          Reshedule
                        </Button>
                      )}
                      <hr />
                    </div>
                    <div>
                      <div>
                        <h5>Customer</h5>
                      </div>
                      <div>
                        {selectedbooking?.user?.first_name && (
                          <p>
                            Name :{" "}
                            {selectedbooking?.user?.first_name +
                              " " +
                              selectedbooking?.user?.last_name}
                          </p>
                        )}
                        {selectedbooking?.user?.gender && (
                          <p>Gender : {selectedbooking?.user?.gender}</p>
                        )}
                        {selectedbooking?.user?.email && (
                          <p>Email : {selectedbooking?.user?.email}</p>
                        )}
                        {selectedbooking?.user?.phone && (
                          <p>Phone : {selectedbooking?.user?.phone}</p>
                        )}
                        {selectedbooking?.user?.dob && (
                          <p>D.O.B : {selectedbooking?.user?.dob}</p>
                        )}
                        {selectedbooking?.user?.blood_group && (
                          <p>
                            Blood Group : {selectedbooking?.user?.blood_group}
                          </p>
                        )}
                      </div>
                      <hr />
                    </div>
                  </div>
                </CustomViewModalStyle>
              </ModalBootStrap.Body>
              <ModalBootStrap.Footer>
                <Button danger onClick={handleCancel}>
                  Close
                </Button>
              </ModalBootStrap.Footer>
            </ModalBootStrap>
          </>
        );
      },
    },
    {
      type: "reschedule_booking",
      title: "Reschedule Booking",
      Component({
        open,
        confirmLoading,
        handleCancel,
        setConfirmLoading,
        selectedbooking,
        messageApi,
      }: any) {
        return (
          <>
            <ModalBootStrap
              show={open}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <ModalBootStrap.Header>
                <ModalBootStrap.Title id="contained-modal-title-vcenter">
                  {this.title}
                </ModalBootStrap.Title>
                <Button danger onClick={handleCancel}>
                  Close
                </Button>
              </ModalBootStrap.Header>
              <ModalBootStrap.Body>
                <RescheduleBooking setIsReschedule={handleCancel} selectedbooking={selectedbooking} />
              </ModalBootStrap.Body>
              <ModalBootStrap.Footer>
                <Button danger onClick={handleCancel}>
                  Close
                </Button>
              </ModalBootStrap.Footer>
            </ModalBootStrap>
          </>
        );
      },
    },
    {
        type: "test_details",
        title: "Test Details",
        Component({
          open,
          confirmLoading,
          handleCancel,
          setConfirmLoading,
          selectedbooking,
          messageApi,
        }: any) {
          return (
            <>
              <ModalBootStrap
                show={open}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <ModalBootStrap.Header>
                  <ModalBootStrap.Title id="contained-modal-title-vcenter">
                    {this.title}
                  </ModalBootStrap.Title>
                  <Button danger onClick={handleCancel}>
                    Close
                  </Button>
                </ModalBootStrap.Header>
                <ModalBootStrap.Body>
                  <LabTestDetails selectedbooking={selectedbooking}/>
                </ModalBootStrap.Body>
                <ModalBootStrap.Footer>
                  <Button danger onClick={handleCancel}>
                    Close
                  </Button>
                </ModalBootStrap.Footer>
              </ModalBootStrap>
            </>
          );
        },
      },
  ];

  export default actions