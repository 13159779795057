import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP } from "../../../config";
import { post, put, get, patch } from "../../../library/Requests/helpers";


export const getAllVendorBookings = createAsyncThunk(
  "bookings/vendorBookingsAPI",
  async (iID:any) => await get(`${SERVER_IP}/api/v1/booking/vendorBookings/${iID}`)
);

export const updateVendorStatusAPI = createAsyncThunk(
  "bookings/updateVendorStatusAPI",
  async (body: any) => await put(`${SERVER_IP}/api/v1/booking/vendorBookings/update`, body)
);

export const vendorBookingReschedule = createAsyncThunk(
  "bookingScreen/bookAgainAPI",
  async (bkAgainObj: any) =>
    await put(
      `${SERVER_IP}/api/v1/booking/${bkAgainObj.bookingId}/reschedule`,
      bkAgainObj.bkAgainObjBody
    )
);

