import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getTestDetailsAPI } from "../../redux/slices/labTest/labTestService";
import { toast } from "react-toastify";
import { Card, Spinner } from "react-bootstrap";
import { LabTestDetailsStyle } from "./LabTestDetails.style";

const LabTestDetails = ({ selectedbooking }: any) => {
  const dispatch = useDispatch();
  const [testDetails, setTestDetails] = useState(null) as any;

  const components = [
    {
      type: "test",
      Component() {
        return (
          <>
            <div className="common-div">
              <h6>Test Name</h6>
              <h6>
                <b>{testDetails?.service_name}</b>
              </h6>
            </div>
            <div className="common-div">
              <h6>Description</h6>
              <h6>
                <b>{testDetails?.description}</b>
              </h6>
            </div>
          </>
        );
      },
    },
    {
      type: "package",
      Component() {
        return (
          <>
            <div className="common-div">
              <h6>Test Name</h6>
              <h6>
                <b>{testDetails?.service_name}</b>
              </h6>
            </div>
            <div className="common-div">
              <h6>Description</h6>
              <h6>
                <b>{testDetails?.description}</b>
              </h6>
            </div>
            <div>
                <Card>
                  <Card.Header>
                    All Tests
                  </Card.Header>
                    <Card.Body>
                      <ul>
                        {testDetails?.tests?.map((test: any) => {
                          return <li>{test?.service_name}</li>;
                        })}
                      </ul>
                    </Card.Body>

                </Card>
            </div>
          </>
        );
      },
    },
  ];

  const testType = selectedbooking?.test_code
    ? components[0]
    : selectedbooking?.package_code
    ? components[1]
    : null;

  useEffect(() => {
    const getTestDetails = async () => {
      try {
        const result = (await dispatch(
          getTestDetailsAPI({ data: selectedbooking, type: testType?.type })
        )) as any;
        if (result?.meta?.requestStatus === "fulfilled") {
          if (testType?.type === "test") {
            setTestDetails(result?.payload?.data?.test);
          } else if (testType?.type === "package") {
            setTestDetails(result?.payload?.data?.package);
          } else {
            toast.error("package or test not found");
          }
        } else {
          toast.error("error occured while fetching");
        }
      } catch (error) {
        console.log(error);
        toast.error("error occured while fetching");
      }
    };
    getTestDetails();
  }, [dispatch]);

  if (!testDetails) {
    return (
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    );
  }

  return(
    <LabTestDetailsStyle>
        {testType?.Component()}
    </LabTestDetailsStyle>
  ) 
};

export default LabTestDetails;
