import styled from "styled-components";

export const BookingTableLoadingStyle = styled.div`
    .spinnerStyled{
        text-align: center;
    }
`;

export const BookingTableStyle = styled.div`
    .test-code-button{
        box-shadow:none
    }
`;
