import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAllVendorBookings } from "./vendorService";

export interface ExtensionDashboardState {
  loading: boolean;
  error: any;
  bookingList: any;
}

const initialState: ExtensionDashboardState = {
  loading: false,
  error: null,
  bookingList: [{}],
};

export const vendorSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading: (
      state: ExtensionDashboardState,
      action: PayloadAction<boolean>
    ) => {
      state.loading = action.payload;
    },

    updateErrorMessage: (
      state: ExtensionDashboardState,
      action: PayloadAction<boolean>
    ) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(getAllVendorBookings.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAllVendorBookings.fulfilled,
      (state: ExtensionDashboardState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.bookingList = action.payload?.data;
      }
    );
    builder.addCase(
      getAllVendorBookings.rejected,
      (state: ExtensionDashboardState, action: any) => {
        state.loading = false;
      }
    );
  },
});

export const { setLoading, updateErrorMessage } = vendorSlice.actions;

export default vendorSlice.reducer;
