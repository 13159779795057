export const getAllDoctorsQuery = (body) => {
  const queryObj = {
    query: `{ doctors (searchText: "${body?.searchText}", type: "${
      body?.type ? body?.type : "all"
    }",  count: ${body?.pageSize}, page: ${body?.pageNo}, activeStatus: "${
      body?.status ? body?.status : "all"
    }") {
      id
      name
      languages
      specialization
      medical_registration_body
      highest_education
      zip
      city
      gst
      type
      registration_number
      registration_expiry_date
      work_experience_years
      rating
      virtual_consultation_cost
      chat_consultation_cost
      call_consultation_cost
      gender
      image
      slot_start_time
      slot_end_time
      available_in_90
       state
    medical_registration_file
    pan_file
    aadhar_file
    aadhar_number
    pan_number
    status
      user{
        id
        email
        phone
        secondary_phone
        secondary_email
      }
      bank_details{
        id
        account_number
        name
        ifsc_code
      }
}
    doctorCount(searchText: "", type: "all", activeStatus: "all")
  }
`,
  };
  return queryObj;
};

export const getAllClientsQuery = (body) => {
  const queryObj = {
    query: `{clients(type:"all",  activeStatus: "active"){
  id
  name
  logo_url
  user_max
  user_count
  status
  parentClient{
  id
  name
  }
}}
`,
  };
  return queryObj;
};


export const getTestDetailsQuery = (body) => {
  const queryObj = {
    test: {
      query: `{
      test(service_code: "${body?.data?.test_code}", city: "", sort: "discount") {
        service_code,
        service_name,
        description,
        preperation,
        price{
          actual_cost,
          discount_percentage,
          discounted_price
        },
        fasting,
        visit_type,
        image,
        vendors{
          id,
          name,
          city,
          state,
          address,
          image,
           price{
          actual_cost,
          discount_percentage,
          discounted_price
        },
        }
      }
    }`,
    },
    package:{
      query:`{
      package(service_code:"${body?.data?.package_code}", city:"", collection_type: "center"
    ,  sort: "discount"){
        service_code
        service_name
        image
        description
        preperation
        fasting
        price {
          actual_cost
          discount_percentage
          service_charges
          discounted_price
        }
        sort
        tests {
          service_code
          service_name
          image
          description
          preperation
          fasting
          price {
            actual_cost
            discount_percentage
            service_charges
            discounted_price
          }
          visit_type
        }
        vendors {
          available_in_90
          id
          name
          address
          city
          zip
          type
          image
          price {
        actual_cost
        discount_percentage
        discounted_price
        service_charges
      }
        }
      }
    }`
    }
  };
  
  return queryObj[body?.type]
};


