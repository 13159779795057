import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 20,
    backgroundColor: "#f8f9fa",
  },
  header: {
    marginBottom: 20,
    borderBottom: "1px solid #ddd",
    paddingBottom: 10,
  },
  section: {
    marginBottom: 20,
  },
  table: {
    marginBottom: 20,
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#b0b0b0",
    borderBottomStyle: "solid",
  },
  tableCol: {
    width: "25%",
    padding: 5,
  },
  tableCell: {
    fontSize: 10,
  },
  footer: {
    marginTop: 20,
    borderTop: "1px solid #ddd",
    paddingTop: 10,
    textAlign: "center",
    fontSize: 10,
  },
});

// Create Invoice Document Component
const MyInvoiceDocument = ({ invoiceData }: any) => (
  <Document>
    <Page size="A4" style={styles.page}>
      {/* Header */}
      <View style={styles.header}>
        <Text style={{ fontSize: 18, fontWeight: "bold" }}>
          {invoiceData.companyName}
        </Text>
        <Text>{invoiceData.companyAddress}</Text>
      </View>

      {/* Client Section */}
      <View style={styles.section}>
        <Text style={{ marginBottom: 5 }}>Invoice To:</Text>
        <Text>{invoiceData.clientName}</Text>
        <Text>{invoiceData.clientAddress}</Text>
      </View>

      {/* Invoice Details Section */}
      <View style={styles.section}>
        <Text>Invoice #: {invoiceData.invoiceNumber}</Text>
        <Text>Date: {invoiceData.date}</Text>
      </View>

      {/* Itemized Table */}
      <View style={styles.table}>
        {/* Table Header */}
        <View style={styles.tableRow}>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Item</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Quantity</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Price</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Total</Text>
          </View>
        </View>
        {/* Table Rows */}
        {invoiceData.items.map((item: any, index: any) => (
          <View key={index} style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{item.name}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{item.quantity}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{item.price}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{item.total}</Text>
            </View>
          </View>
        ))}
      </View>

      {/* Footer */}
      <View style={styles.footer}>
        <Text>Total: ${invoiceData.total}</Text>
      </View>
    </Page>
  </Document>
);

export default MyInvoiceDocument;
