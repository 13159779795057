import { Calendar, Space } from "antd";
import React, { useEffect, useState } from "react";
import { CustomRescheduleBookingStyle } from "./RescheduleBooking.style";
import moment from "moment";
import { Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllVendorBookings,
  vendorBookingReschedule,
} from "../../../redux/slices/vendor/vendorService";
import { toast } from "react-toastify";
import { RootState } from "../../../redux/store";
import { getTimeSlotsAPI } from "../../../redux/slices/timeslot/timeSlotService";
import TimeSlotcomp from "../../timeSlot";
import TextArea from "antd/lib/input/TextArea";

const RescheduleBooking = ({ selectedbooking, setIsReschedule }: any) => {
  const dispatch = useDispatch();

  const { user } = useSelector((store: RootState) => store.auth);
  const [reason, setReason] = useState("");

  const today = moment().startOf("day");
  const [selectedDate, setSelectedDate] = useState<any>(today);
  const disabledDate = (current: any) => {
    return current && current.isBefore(today);
  };

  const onDateChange = (date: any) => {
    if (date) {
      console.log("Selected date:", date.format("YYYY-MM-DD"));
      setSelectedDate(date);
    } else {
      console.log("No date selected");
      setSelectedDate(null);
    }
  };

  const formattedDate =
    selectedDate &&
    moment(selectedDate, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ").format(
      "DD/MM/YYYY"
    );

  const { loading } = useSelector((store: RootState) => store.timeslot);

  useEffect(() => {
    if (formattedDate) {
      const body = {
        type: "vendor",
        date: moment(formattedDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
        vendorId: 24,
        test_code: selectedbooking?.test_code,
      };
      dispatch(getTimeSlotsAPI(body));
      setSelectedSlot(null);
    }
  }, [formattedDate, dispatch, selectedbooking]);

  const handleReschedule = async () => {
    const bkAgainObjBody = {
      booking: {
        collection_1_date: moment(formattedDate, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        ),
        rescheduleReason: reason,
      },
    } as any;
    if (selectedSlot?.slot) {
      bkAgainObjBody.booking.collection_1_slot = selectedSlot?.slot;
    }
    const bkAgainObj = {
      bookingId: selectedbooking?.id,
      bkAgainObjBody,
    };

    try {
      const result = (await dispatch(
        vendorBookingReschedule(bkAgainObj)
      )) as any;
      if (result?.meta?.requestStatus === "fulfilled") {
        toast.success("booking rescheduled");
        dispatch(getAllVendorBookings(user?.id));
        setIsReschedule();
      } else if (result?.meta?.requestStatus === "rejected") { 
        toast.error(result?.error?.message);
      }
    } catch (error) {
      toast.error("error occured");
    }
  };

  const [selectedSlot, setSelectedSlot] = useState<any>(null);

  const handleTimeSlotClick = (item: any) => {
    console.log(item);
    setSelectedSlot(item);
  };

  return (
    <CustomRescheduleBookingStyle>
      <div className="reschedule-main-div">
        <div>
          <Space direction="vertical">
            <div className="site-calendar-demo-card">
              <Calendar
                value={selectedDate}
                disabledDate={disabledDate}
                onChange={onDateChange}
                fullscreen={false}
              />
            </div>
          </Space>
        </div>
        <div className="main-div">
          <div>
            {loading ? (
              <Spinner animation="border" />
            ) : (
              <TimeSlotcomp
                selectedSlot={selectedSlot}
                handleClick={handleTimeSlotClick}
              />
            )}
          </div>
          <div className="booking-display-text1">
            <div className="display-flex">
              <p className="schedule-p">Previous Schedule:</p>
              <b>
                {selectedbooking?.collection_1_date &&
                  selectedbooking?.collection_1_date}{" "}
                {selectedbooking?.collection_1_slot &&
                  `at ${selectedbooking?.collection_1_slot}`}
              </b>
            </div>
            <div className="display-flex">
              <p className="schedule-p">After Reschedule:</p>
              <b>
                {formattedDate && formattedDate}{" "}
                {selectedSlot?.slot && `at ${selectedSlot.slot}`}{" "}
              </b>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="handle-reschedule-submit-group">
          <TextArea
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            placeholder="Reason"
            rows={4}
          />
        </div>
        <div className="handle-reshedule-btn-div">
          <Button
            disabled={!selectedDate || !reason}
            onClick={handleReschedule}
          >
            Confirm Reshedule
          </Button>
        </div>
      </div>
    </CustomRescheduleBookingStyle>
  );
};

export default RescheduleBooking;
