import React, { useEffect, useState } from "react";
import { Table, Dropdown, Space, Spin } from "antd";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { BookingTableLoadingStyle,BookingTableStyle } from "./BookingTable.style";

const BookingTable = ({ instituteAction, bookingList, tabType }: any) => {
  const { loading } = useSelector((state: any) => state?.vendor);
  console.log(bookingList)

    const [width,setWidth] = useState(()=>{
      return window?.innerWidth
    })
  
    useEffect(()=>{
  
      const handleResize = () => {
        setWidth(window?.innerWidth)
    };
  
      window.addEventListener("resize",handleResize)
  
      return ()=>window.removeEventListener("resize",handleResize)
    },[])

  const columns = [
    {
      title: "Code",
      width: 150,
      dataIndex: "id",
      key: "0",
      fixed: width > 768?"left":"",
    },
    {
      title: "Test Code",
      width: 200,
      key: "1",
      render:(item:any)=>{
        const testCode = item?.test_code || item?.package_code
        return(
          <>
            <Button onClick={()=>instituteAction(item,"test_details",)} className="test-code-button" variant="link">{testCode}</Button>
          </>
        )
      }
    },
    {
      title:"Name",
      width:200,
      key:"2",
      render:(item:any)=>{
        return(
          item?.user?.first_name+" "+item?.user?.last_name
        )
      }
    },
    {
      title:"Email",
      width:200,
      key:"9",
      render:(item:any)=>{
        return(
          item?.user?.email
        )
      }
    },

    {
      title: "Vendor Status",
      dataIndex: "vendor_status",
      key: "4",
      width: 200,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "5",
      width: 200,
    },
    {
      title: "Booking Date",
      dataIndex: "collection_1_date",
      key: "6",
      width: 150,
    },
    {
      title: "Booking Time",
      width: 200,
      dataIndex: "collection_1_slot",
      key: "7",
    },
  ] as any;

  if (tabType !== "rejected") {
    columns.push({
      title: "Action",
      key: "8",
      fixed: "right",
      width: 100,
      render: (item: any) => {
        // Dynamically set dropdown options based on doctor status
        const actionOptions = [
          {
            type: "open",
            Component: (
              <Dropdown
                placement="topLeft"
                className="menu-items-sec-all"
                menu={{
                  items: [
                    {
                      key: "accept",
                      label: (
                        <span className="drop-down-single-item-sec">
                          Accept Booking
                        </span>
                      ),
                    },
                    {
                      key: "reject",
                      label: (
                        <span className="drop-down-single-item-sec">
                          Reject Booking
                        </span>
                      ),
                    },
                    {
                      key: "view_booking",
                      label: (
                        <span className="drop-down-single-item-sec">
                          View Booking
                        </span>
                      ),
                    },
                    {
                      key: "reschedule_booking",
                      label: (
                        <span className="drop-down-single-item-sec">
                          Reschedule Booking
                        </span>
                      ),
                    },
                  ],
                  onClick: ({ key }) => {
                    instituteAction(item, key, actionList?.type);
                  },
                }}
              >
                <span className="hover-menu-item-sec">...</span>
              </Dropdown>
            ),
          },
          {
            type: "accepted",
            Component: (
              <Button
                type="primary"
                onClick={() =>
                  instituteAction(item, "upload_lab_result", "accepted")
                }
                size="sm"
              >
                Upload Documents
              </Button>
            ),
          },
          {
            type: "completed",
            Component: (
              <Button
                type="primary"
                onClick={() =>
                  instituteAction(item, "preview_documents", "completed")
                }
                size="sm"
              >
                Preview Documents
              </Button>
            ),
          },
        ];

        const actionList = actionOptions.find(
          (element) => element?.type === item?.vendor_status
        );

        return (
          <Space size="middle">
            {actionList?.Component ? actionList.Component : null}
          </Space>
        );
      },
    });
  }

  if (loading) {
    return (
      <>
        <BookingTableLoadingStyle>
          <div className="spinnerStyled">
            <Spin />
          </div>
        </BookingTableLoadingStyle>
      </>
    );
  }

  return (
    <BookingTableStyle>
      <Table
        columns={columns}
        pagination={false}
        dataSource={bookingList}
        scroll={{ x: 1500, y: 500 }}
        rowClassName={(record, index) =>
          `row_selected_priority_${record?.priority}`
        }
      />
    </BookingTableStyle>
  );
};

export default BookingTable;
