import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  createUsersChatId,
  intiateChat,
} from "../../redux/slices/chat/chatService";
import Loader from "../Common/Loader";
import { toast } from "react-toastify";
import { ChatContext } from "../../pages/Chat/context/ChatConext";
import UserProfileImage from "./UserProfileImage";

type prop = {
  onHide: () => void;
  onSubmit: () => void;
};
const NewChatForm = ({ onHide, onSubmit }: prop) => {
  const { handleSendEvent, userChatId, handleOpenChat } =
    useContext(ChatContext);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  // const { allAdmins, loading } = useSelector(
  //   (state: any) => state?.userManagement
  // );

  const [search, setSearch] = useState("");

  const admins = useMemo(() => {
    const allAdmins: any = [
      {
        id: 1,
        first_name: "Aniya",
        last_name: "Blick",
        email: "admin@raphacure.co.in",
      },
    ];
    if (!search) return allAdmins;
    const regex = new RegExp(search, "i");
    return allAdmins?.filter(
      (admin: any) =>
        regex.test(`${admin.first_name} ${admin.last_name}`) ||
        regex.test(admin.email)
    );
  }, [search]);

  console.log(admins);

  // useEffect(() => {
  //   getAllAdminsApi();
  // }, [getAllAdminsApi]);

  const handleStartNewChat = async (selectedUser: any) => {
    setIsLoading(true);
    const result: any = await dispatch(
      intiateChat({
        body: selectedUser,
        userId: userChatId,
      })
    );

    if (!result?.payload?.status) {
      toast.error(result?.payload?.msg ?? "Failed to initiated chat!.");
      setIsLoading(false);
      return;
    }
    const user = result?.payload?.data;
    const isChatInitiated = user?.isChatInitiated;

    if (!isChatInitiated) {
      await handleSendEvent({
        chatType: "INDIVIDUAL_CHAT",
        to: user?._id,
        event: {
          created_by: userChatId,
          type: "CHAT_INITIATED",
          created_for: user?._id,
        },
      });
    }

    setIsLoading(true);
    onHide();
    await handleOpenChat({
      opponent: {
        chatId: user?._id,
        chatType: "INDIVIDUAL_CHAT",
        name: user?.name,
        photo: user?.image,
        unReadMessageCount: user?.unReadMessageCount,
      },
      type: "INDIVIDUAL_CHAT",
    });

    // handleSendEvent({
    //   event: {
    //     created_by: userChatId,
    //     type: "CHAT_INITIATED",
    //   },
    //   chatType: "INDIVIDUAL_CHAT",
    //   to:
    // })
  };

  return (
    <>
      {isLoading && <Loader />}
      <Modal
        show={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="add-user-model-popup"
        onHide={onHide}
      >
        <Modal.Header closeButton>
          <Modal.Title>Start New Chat</Modal.Title>
        </Modal.Header>

        <Modal.Body
          className={`modalBodyDefault alert-model-popup-sec dynamic_class_`}
        >
          <Form.Control
            type="text"
            name="name"
            placeholder="Search name or email"
            value={search ?? ""}
            onChange={(e) => {
              setSearch(e?.target.value);
            }}
          />
          <StyledComp>
            <div className="particpantsListContainer mt-2">
              {admins?.map((admin: any) => {
                return (
                  <div
                    className="d-flex align-items-center p-2 row"
                    onClick={() => {
                      handleStartNewChat({
                        email: admin?.email,
                        id: admin?.id,
                        image: admin?.image,
                        name: `${admin?.first_name ?? ""} ${
                          admin?.last_name ?? ""
                        }`,
                      });
                    }}
                  >
                    <UserProfileImage
                      name={`${admin?.first_name || ""} ${
                        admin?.last_name || ""
                      }`}
                      url={admin?.image}
                    />
                    <div className="title ml-2">
                      {admin?.first_name || ""} {admin?.last_name || ""}
                    </div>
                    <div className="subtitle ml-2">({admin?.email || ""})</div>
                  </div>
                );
              })}
            </div>
          </StyledComp>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NewChatForm;

const StyledComp = styled.div`
  .particpantsListContainer {
    border-radius: 5px;
    max-height: 250px;
    overflow-y: auto;
    border: 1px solid #ced4da;
    text-transform: capitalize;
    padding: 0px 20px;

    input[type="checkbox"] {
      width: 20px;
      height: 20px;
    }
  }

  .row {
    color: #252b61;
    cursor: pointer;

    &:hover {
      background: #f8f8ff;
    }
  }

  .title {
    font-size: 18px;
  }
  .subtitle {
    font-size: 14px;
    color: #343434;
  }
`;
