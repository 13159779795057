import React, { useContext, useEffect, useState } from "react";
import { Dropdown, Menu, Button } from "antd";
import { BsThreeDotsVertical } from "react-icons/bs";
import NewChatForm from "./NewChatForm";
import { ChatContext } from "../../pages/Chat/context/ChatConext";

const ThreeDotMenu = () => {
  const { allInitiatedChatUsersApi } = useContext(ChatContext);

  const [showNewChatForm, setShowNewChatForm] = useState(false);

  const items = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            setShowNewChatForm(true);
          }}
        >
          New Chat
        </div>
      ),
    },
  ];

  return (
    <>
      {showNewChatForm && (
        <NewChatForm
          onHide={() => {
            setShowNewChatForm(false);
          }}
          onSubmit={async () => {
            await allInitiatedChatUsersApi();
            setShowNewChatForm(false);
          }}
        />
      )}
      <Dropdown menu={{ items }} trigger={["click"]} placement="bottomRight">
        <BsThreeDotsVertical className="menu" />
      </Dropdown>
    </>
  );
};

export default ThreeDotMenu;
