import styled from "styled-components";

export const CustomRescheduleBookingStyle = styled.div`
  .site-calendar-demo-card {
    width: 300px;
    border: 1px solid #f0f0f0;
    border-radius: 2px;
  }

  .main-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
  }
  .booking-display-text1 {
    margin-top: 10px;
  }

  .handle-reshedule-btn-div {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .display-flex {
    display: flex;
  }

  .schedule-p {
    flex-shrink: 0;
  }

  .reschedule-main-div {
    display: flex;
    gap: 40px;
  }

  .handle-reschedule-submit-group {
    margin-top:10px;
    padding-left:60px;
    padding-right:60px;
  }
`;
