import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP } from "../../../config";
import { post} from "../../../library/Requests/helpers";
import { getTestDetailsQuery } from "../../../Scenes/graphqlConfig";

export const getTestDetailsAPI = createAsyncThunk(
  "Profile/getTestDetails",
  async (body: any) =>
    await post(`${SERVER_IP}/graphql`, getTestDetailsQuery(body))
);

