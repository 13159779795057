import React from "react";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import MyInvoiceDocument from "./MyInvoiceDocument";

const PDF = () => {
  const invoiceData = {
    companyName: "Acme Corp",
    companyAddress: "123 Business Rd, Business City, BC",
    clientName: "John Doe",
    clientAddress: "789 Client St, Client City, CC",
    invoiceNumber: "12345",
    date: "2024-12-20",
    items: [
      { name: "Design Services", quantity: 1, price: 500, total: 500 },
      { name: "Development Services", quantity: 2, price: 1000, total: 2000 },
    ],
    total: 2500,
  };

  return (
    <div>
      <PDFViewer style={{ width: "100%", height: "500px" }}>
        <MyInvoiceDocument invoiceData={invoiceData} />
      </PDFViewer>

      <div style={{ marginTop: "20px" }}>
        <PDFDownloadLink
          document={<MyInvoiceDocument invoiceData={invoiceData} />}
          fileName="invoice.pdf"
        >
          Download Invoice PDF
        </PDFDownloadLink>
      </div>
    </div>
  );
};

export default PDF;
