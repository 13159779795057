import styled from "styled-components";

export const ModalStyle = styled.div`
    .upload_modal{
        display:flex;
        flex-direction: column-reverse;
        gap:10px
    }

    .custom-upload-files{
        display:flex
        justify-content:space-between
    }
`;

export const CustomUploadStyle = styled.div`
    .custom-upload-item{
        padding:2px;
        display:flex;
        justify-content:space-between;
        border-radius: 6px;
    }
    
    .custom-upload-item:hover{
         background-color: 	rgb(245,245,245);
    }
`;

export const CustomViewModalStyle = styled.div`
    .customer-details{
        color:blue;
    }

    .site-calendar-demo-card {
        width: 300px;
        border: 1px solid #f0f0f0;
        border-radius: 2px;
    }
    .main-div {
  padding-left: 40px;
  padding-right: 40px;
}
  .main-id {
  margin-bottom: 40px;
}
  .sub-div-main {
  display: flex;
  justify-content: space-between;
}

.current-status {
  font-size: 20px;
}

.scheduled-booking-head {
  display: flex;
  justify-content: space-between;
}


.custom-space-width {
  width: 100%;
}




`;
export const CustomPreviewModalStyle = styled.div`
    .main-div{
        display:flex;
        flex-direction:column;
    }
`;

