import React, { useEffect, useState } from "react";
import SearchUser from "../searchUser/Index";
import BookingTable from "../bookingTable/BookingTable";
import { message, Pagination} from "antd";
import actions from "../bookingModals/BookingModals";

const BookingTab = ({
  tabType,
  bookings,
}: {
  tabType: string;
  bookings: any;
}) => {
  const [selectedAction, setSelectedAction] = useState("");
  const [selectedbooking, setSelectedBooking] = useState({} as any);
  const [pageSize, setPageSize] = useState(50);
  const [pageNo, setPageNo] = useState(1);
  const [messageApi, contextHolder] = message.useMessage();
  const [filteredUsers,setFilteredUsers] = useState([])
  const [filter,setFilter] = useState({
    search:""
  })

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showBookingModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
    setSelectedAction("");
    setSelectedBooking("");
  };

  const instituteAction = (bookingDetails: any, action: any, type: string) => {
    if ( !bookingDetails || !action) return;

    const allowedAction = [
      "accept",
      "reject",
      "upload_lab_result",
      "preview_documents",
      "view_booking",
      "reschedule_booking",
      "test_details"
    ];

    if (allowedAction.includes(action)) {
      setSelectedAction(action);
      setSelectedBooking(bookingDetails);
      showBookingModal();
    }
  };

  const handlePageChange = (val: any, val1: any) => {
    setPageSize(val1);
    setPageNo(val);
  };

  const filterFunction = ()=>{
    const filtered = bookings?.filter((item:any)=>{
      return item?.user?.first_name?.toLowerCase()?.includes(filter?.search?.toLowerCase()) || item?.user?.last_name?.toLowerCase()?.includes(filter?.search?.toLowerCase()) || item?.user?.email?.toLowerCase()?.includes(filter?.search?.toLowerCase()) || item?.user?.phone?.toLowerCase()?.includes(filter?.search?.toLowerCase())
    })
    setFilteredUsers(filtered)
  }

  useEffect(()=>{
    filterFunction()
  },[filter,bookings])

  useEffect(() => {
    const totalPages = Math.ceil(filteredUsers?.length / pageSize);
    if (pageNo > totalPages) {
      setPageNo(1);
    }
  }, [filteredUsers, pageSize]);

  return (
    <>
      {contextHolder}
      <div className="content getinTouchPage">
        <SearchUser setFilter={setFilter} />
        <div>
          <div className="total-count-row"></div>
          <div className="institutes-filters"></div>
          <div className="all-institutes-data rapha-table-view">
            <BookingTable
              tabType={tabType}
              instituteAction={instituteAction}
              bookingList={filteredUsers?.slice(
                (pageNo - 1) * pageSize,
                (pageNo - 1) * pageSize + pageSize
              )}
            />

            <div className="rapha-table-pagination">
              <div>
                <span>
                  Showing{" "}
                  {filteredUsers?.length < pageSize ? filteredUsers?.length : pageSize} of{" "}
                  {filteredUsers?.length} Users
                </span>
              </div>
              <div>
                <Pagination
                  showSizeChanger={true}
                  onChange={handlePageChange}
                  pageSize={pageSize}
                  current={pageNo}
                  total={filteredUsers?.length}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <BookingModal
        action={selectedAction}
        open={open}
        handleCancel={handleCancel}
        confirmLoading={confirmLoading}
        setConfirmLoading={setConfirmLoading}
        selectedbooking={selectedbooking}
        messageApi={messageApi}
      />
    </>
  );
};

function BookingModal({
  action,
  open,
  handleCancel,
  confirmLoading,
  setConfirmLoading,
  selectedbooking,
  messageApi,
}: any) {
  return (
    <>
      {actions
        .find((element) => element.type === action)
        ?.Component({
          open,
          confirmLoading,
          handleCancel,
          setConfirmLoading,
          selectedbooking,
          messageApi,
        })}
    </>
  );
}

export default BookingTab;
